body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.hide-border:before,
.hide-border:after {
  display: none;
}
.dd-item::marker {
  display: none !important;
  font-size: 0px;
}
.btn-outline-secondary:hover {
  color: #ffffff !important;
}

.rsm-svg {
  height: 100%;
  width: 100%;
}

.modal {
  z-index: 11112 !important;
}

.image-gallery-play-button {
  display: none;
}

.image-gallery {
  width: 50%;
}
.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
  height: 70px !important;
  width: 60px !important;
}

.card .header .header-dropdown li .dropdown-menu,
.header-dropdown .dropdown-menu {
  background: #17191c;
  border-radius: 0.55rem;
  overflow: hidden;
  transform: none !important;
  left: auto !important;
  right: 10px !important;
  padding: 10px;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.5);
  border: 0;
  top: 10px !important;
  color: #999 !important;
}
.card .header .header-dropdown li a {
  color: #999 !important;
  padding: 10px;
  white-space: nowrap;
  font-size: 14px;
}

.card .header .header-dropdown li a:hover {
  color: #fff !important;
}
.accordion .card .card-header {
  background-color: transparent !important;
}
.accordion .card .card-header .btn-link {
  color: #666;
}

.accordion .card-body {
  font-size: 15px;
  line-height: 28px;
  color: #777;
}

.button-container {
  display: none !important;
}

.hide-toggle:after {
  display: none !important;
  margin-left: 0em !important;
  vertical-align: 0em !important;
  content: "" !important;
  border-top: 0em solid !important;
  border-right: 0em solid transparent !important;
  border-bottom: 0 !important;
  border-left: 0em solid transparent !important;
}

.form-control {
  height: calc(1.5em + 0.75rem + 5px) !important;
}

.hide-toggle .btn-outline-secondary {
  height: calc(1.5em + 0.75rem + 5px) !important;
}
.jvectormap-container {
  height: 300px;
}
.jvectormap-zoomin {
  position: absolute;
  top: 0px;
  background: #000;
  color: #fff;
  padding: 2px 7px;
}
.jvectormap-zoomout {
  position: absolute;
  top: 27px;
  background: #000;
  color: #fff;
  padding: 2px 7px;
}
.list-group-item + .list-group-item {
  border-top-width: 1px !important;
}
.hclass {
  left: -40px !important;
}

.MuiButtonBase-root {
  font-weight: 700;
}

.html-button {
  position: absolute;
  top: 10px;

  cursor: pointer;
  right: 11px;
}

.ql-html-buttonCancel {
  background-color: white !important;
  border: 1px solid white !important;
  font-size: 12px;
  color: black;
  min-width: 60px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
}

.ql-html-buttonOk {
  background-color: #5cbe85 !important;
  border: 1px solid #5cbe85 !important;
  font-size: 12px;
  color: white;
  min-width: 60px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px;
}

.ql-html-buttonGroup {
  bottom: -25px !important;
  display: flex;
}

/* .ql-html-popupTitle {
  display: none !important;
} */

.upload-block {
  max-width: 800px;
  /* width: 800px; */
  padding: 30px;
  padding-bottom: 0px !important;

}

.drop-file-container{

}

.wrapper-file{
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  align-items: center;

}
#main-content{
  margin-top: 0px !important;
}

.wrapper-full{
  height: 100vh !important;
}
.upload-container{
  max-width: 800px;
  /* width: 500px; */
  width: 100%;
  padding-top: 0px !important;
  padding: 30px;
  padding-bottom: 10px;
}


.upload-container ul{
  padding: 0;
  list-style: none;
}

.upload-container li{
text-decoration: none;
padding: 12px 10px;
}

.upload-container li+li{
  border-top: 1px dashed #0096FF;
  
  }
  
  .list li+li{
    border-top: 1px dashed #0096FF;
    }


    .list .files-wrapper{
      padding-left: 0px;
  
    }
    .list ul{
      list-style: none;
    }
    .list li{
      display: flex;
      align-items: center;
      width: 100%;
    min-height: 50px;
    padding: 0px 20px;

    }
    .over-flow-i{
      overflow: auto !important;
    }

    .folder-a{
      background-color: rgba(0, 151, 255, 0.1);
    }