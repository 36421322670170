/* Layout */

#wrapper {
  &:before,
  &:after {
    height: 100vh;
    width: 5px;
    position: fixed;
    content: "";
    z-index: 999;
    top: 0;
  }
  &:before {
    left: 0;
  }
  &:after {
    right: 0;
  }

  @include max-screen($break-medium) {
    &:before,
    &:after {
      width: 2px;
    }
  }
}

.card-body-fixed {
  position: fixed !important;
  right: 29px !important;
  left: calc(#{$sidebar-width} + 15px);
  // &:before {
  //   left: calc(#{$sidebar-width} + 15px) !important;
  // }
  // &:after {
  //   left: calc(15px) !important;
  // }
  overflow: hidden !important;
  bottom: 0px !important;
  top: 140px !important;
  width: auto !important;
  @include max-screen($break-large) {
    left: 20px !important;
    padding: 0;
  }
}

#main-content {
  @include transition(all 0.3s ease-in-out);
  width: calc(100% - #{$sidebar-width});
  float: right;
  position: relative;
  margin-top: 65px;
  padding: 0 10px;
  @include max-screen($break-large) {
    width: 100%;
    padding: 0;
  }
}

html.fullscreen-bg,
html.fullscreen-bg body,
html.fullscreen-bg #wrapper {
  height: 100%;
}

.vertical-align-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  display: table;
}

.vertical-align-middle {
  display: table-cell;
  vertical-align: middle;
}
