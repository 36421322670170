$circle-length: 151px;
$check-length: 36px;
html {
  height: 100%;
}

.breadcrumb-item.active {
  a {
    color: #6c757d;
  }
}

.btn {
  outline: none !important;
  box-shadow: none !important;
}
.custom-audio-player {
  box-shadow: none !important;
  background: transparent !important;
  .rhap_progress-section {
    display: none !important;
  }
  .rhap_controls-section {
    margin-top: 0 !important;
  }
}

.agent-wrapper {
  max-width: 400px;
  width: 400px;
  height: 400px;
}

.button-buttom-div {
  left: 0px;
  position: absolute;
  width: 100%;
  bottom: 0px;
  /* right: 20px; */
  padding: 20px;
}
.w-128 {
  width: 128px !important;
}
.h-40 {
  height: 40px !important;
}

.fab-content {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 2;
  button {
    border-radius: 200px !important;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
    font-size: 1.4rem;

    span {
      margin-left: 10px;
      font-size: 1rem;
    }
  }
}

@media (max-width: 768px) {
  .button-top {
    position: static !important;
    margin-top: -20px !important;
    margin-bottom: 10px !important;
  }
  .dtmf-card {
    margin-top: 25px !important;
  }
}

.h600 {
  height: 600px !important;
}
.cursor-pointer {
  cursor: pointer !important;
}

.fancy-radio {
  input[type="radio"]:checked + span i:after {
    color: $cyan;
    background-color: $cyan;
  }
}
.custom-select {
  height: auto !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  &:focus {
    outline: none !important;
    box-shadow: none !important;
  }
  .react-dropdown-select-option {
    background-color: $cyan !important;
  }
  .react-dropdown-select-item:hover {
    background-color: rgba(73, 197, 182, 0.12) !important;
    color: black !important;
  }
  .react-dropdown-select-item-selected {
    background-color: $cyan !important;
  }
  .react-dropdown-select-item-selected:hover {
    background-color: $cyan !important;
    color: white !important;
  }
}
.vici-phone {
  position: fixed !important;
  height: 500px !important;
}

.w-600 {
  font-weight: 600 !important;
}
.button-top {
  position: absolute;
  right: 14px;
  top: 4px;
}

.w-700 {
  font-weight: 700 !important;
}

.list-group-item {
  padding: 0.75rem 1.25rem !important;
}

.big-font {
  font-size: 4rem !important;
  font-weight: 600;
}

.user-avatar {
  height: 50px;
  width: 50px;
  object-fit: cover;
}

textarea.h-auto {
  height: auto !important;
}

.w-500 {
  font-weight: 500 !important;
}

// theme custom color
.Mui-selected {
  background-color: rgba(73, 197, 182, 0.12) !important;
}

.text-white {
  color: white !important;
}

.car-width {
  width: 190px !important;
  height: 130px !important;
}

.word-break {
  word-break: break-all !important;
}

.c100 {
  height: calc(100% - 30px);
}

.border-ll-0 {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}

.border-rl-0 {
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.btn-primary2 {
  background-color: #007bff !important;
  border-color: #007bff !important;
  color: white !important;
}

.input-group {
  .form-group {
    width: 100% !important;
    margin-bottom: 0px !important;
  }
}

.agent-form {
  .form-control {
    border-top: 0;
    border-left: 0;
    border-radius: 0;
    border-right: 0;
    padding: 0px 0px 25px 0px;
    color: black;
    background-color: white !important;
    font-size: 0.8rem !important;
    border-color: rgba(0, 0, 0, 0.1);
  }
  .form-control::placeholder {
    color: black;
    font-size: 0.8rem !important;
  }
  .form-control:focus {
    // border-bottom: 1px solid cyan !important;
  }
  label {
    color: #6c757d !important;
    font-size: 0.7rem !important;
    margin-bottom: 5px;
  }
}

.date-picker-custom {
  .react-datepicker-wrapper {
    display: flex !important;
    flex-grow: 1 !important;
  }
}
.nowrap {
  white-space: nowrap !important;
  flex-wrap: nowrap !important;
}

.dialphone-ui {
  position: fixed;
  right: 21px;
  bottom: 81px;
  background-color: white;
  border-radius: 9px;
  overflow: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  width: 279px !important;
  z-index: 999;

  .phone-input {
    background-color: #66bb6a !important;
    color: white !important;
    font-size: 1.5rem;
    padding: 12px 10px !important;
    font-weight: 600;
    border: 0;
    background-color: transparent;
    text-align: center;
    width: 100%;
  }
  .phone-input::placeholder {
    font-weight: 500 !important;
    color: white !important;
  }
  .phone-input:focus {
    border: 0 !important;
    outline: none !important;
  }
  .row {
    margin: 0 auto;

    clear: both;
    text-align: center;
  }

  .fs14 {
    font-size: 1.4rem !important;
  }
  .digit,
  .dig {
    float: left;
    padding: 10px 30px;
    width: 93px;
    font-size: 1.4rem;
    font-weight: 500;
    cursor: pointer;
  }

  .sub {
    font-size: 0.8rem;
    color: grey;
  }

  #output {
    font-family: "Exo";
    font-size: 2rem;
    height: 60px;
    font-weight: bold;
    color: #1976d2;
  }

  #call {
    display: flex;
    background-color: #66bb6a;
    margin: 10px;
    color: white;
    border-radius: 100% !important;
    height: 50px;
    width: 50px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .botrow {
    margin: 0 auto;
    width: 280px;
    clear: both;
    text-align: center;
    font-family: "Exo";
  }

  .digit:active,
  .dig:active {
    background-color: #e6e6e6;
  }

  #call:hover {
    background-color: #81c784;
  }

  .dig {
    float: left;
    padding: 10px 20px;
    margin: 10px;
    width: 30px;
    cursor: pointer;
  }
}

.phone-input {
}

.mb-30 {
  margin-bottom: 30px;
}

.box-green {
  background-color: #28a745 !important;
  div {
    color: white !important;
  }
  h3 {
    color: white !important;
  }
  h2 {
    color: white !important;
  }
  p {
    color: white !important;
  }
}

.dial-right {
  position: absolute;
  top: -53px;
  right: 21px;
  display: flex;
  align-items: center;
}
.phone-status {
  height: 20px !important;
  width: 20px !important;
  border-radius: 200px !important;
  margin-left: 13px !important;
}

.dot-red {
  background-color: red !important;
}
.dot-green {
  background-color: #28a745 !important;
}
.dot-orange {
  background-color: orange !important;
}

.notallowed {
  pointer-events: none !important;
  cursor: not-allowed !important;
}

.text-white {
  color: white !important;
}

.hover-blue:hover {
  background-color: rgba(73, 197, 182, 0.12) !important;
}

.block-red {
  background: red;
  border-radius: 7px !important;
  div {
    color: white !important;
  }
  h2 {
    color: white !important;
  }
  p {
    color: white !important;
  }
}

.digi-active {
  background-color: #e6e6e6;
}

.dashbord-color {
  .red {
    background-color: #ff0000;
    color: white !important;
  }
  .yellow {
    background-color: #ffa500;
    color: white !important;
  }
  .sky {
    background-color: #add8e6;
    color: black !important;
  }
  .blue {
    background-color: #0000ff;
    color: white !important;
  }
  .nevyblue {
    background-color: #191970;
    color: white !important;
  }
  .lightpink {
    background-color: #d8bfd8;
    color: black !important;
  }
  .pink {
    background-color: #ee82ee;
    color: black !important;
  }
  .perple {
    background-color: #800080;
    color: white !important;
  }
  .lightYellow {
    background-color: #f0e68c;
    color: black !important;
  }
  .pureYellow {
    background-color: #ffff00;
    color: white !important;
    color: black !important;
  }
  .darkYellow {
    background-color: #808000;
    color: white !important;
  }
  .green1 {
    background-color: #556b2e;
    color: white !important;
  }

  .wood {
    background-color: #8b4414;
    color: white !important;
  }
  .geeen2 {
    background-color: #006600;
    color: white !important;
  }
  .black {
    background-color: #000;
    color: white !important;
  }
  .color-dot {
    height: 25px;
    width: 25px;
    margin-right: 10px !important;
  }
}

.script-block center {
  display: flex !important;
  margin-bottom: 15px !important;
  font-size: 16px !important;
}

.close-icon {
  position: absolute;
  top: -8px;
  right: -8px;
  color: red;
  font-size: 22px;
  background-color: white;
  border: 100%;
}

.button-spiner {
  color: white !important;
  width: 1.3rem;
  height: 1.3rem;
}
.form-button {
  top: 0px !important;
}

.files-top {
  top: 160px !important;
  z-index: 9999;
  right: 50px !important;
  position: fixed !important;
}

.top-right {
  position: absolute;
  top: 22px;
  z-index: 22;
  right: 20px;
}

.user-image {
  height: 150px !important;
  width: 150px !important;
  border-radius: 5px !important;
  object-fit: contain;
  background-color: #80808012;
}

.pagination-form {
  .form-group {
    margin-bottom: 0 !important;
  }
}

.pagination-div {
  position: absolute;
  bottom: 12px;
  right: 0px;
  background-color: white;
  width: 100%;
  padding-top: 10px;
  padding-right: 21px;
}

.select-error {
  .react-dropdown-select {
    background-color: #fbf5f5;
    border-color: #efd8d8;
  }
}

.folder {
  width: 150px;
  cursor: pointer;
  position: relative;
  height: 100%;
}
.folder::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: black;
  opacity: 0;
  transition: all 0.3s;
}
.folder:hover::before,
.folder:active::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-color: black;
  opacity: 0.05;
}

.folder:active {
  box-shadow: 5px 10px #888888;
}
.folder:hover {
  width: 150px;
  cursor: pointer;
}

textarea.h-auto {
  height: 100px !important;
}
.btn-prymary {
  background-color: #0096FF;
  color: white;
}

.btn-outline-primary {
  border: 1px solid #5cbe85;
  color: #5cbe85;
}

.btn-outline-primary:hover,
.btn-outline-primary:active {
  background-color: #5cbe85;
  border: 1px solid #5cbe85;
  color: white;
}
.btn-prymary:hover {
  background-color: #0096FF;
  color: white;
}

.btn-prymary:active {
  background-color: #0096FF;
  color: white;
}
.table-search-div {
  position: absolute;
  top: 12px;
  right: 0px;
  background-color: white;
  width: 100%;
  padding-top: 10px;
  padding-right: 21px;
}

.search-input {
  .form-group {
    margin-bottom: 0;
  }
  input {
    padding-right: 50px;
  }
  .icon-magnifier {
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }
}
.error-message {
  font-size: 0.9em;
}

.quill {
  .ql-container,
  .ql-editor {
    min-height: 250px;
  }
}

.text-desc {
  color: #777777;
  font-size: 12px;
}

div[disabled] {
  color: #6c757d !important;
  background-color: #e9ecef !important;
  opacity: 0.7;
}
.not-allow-dropdown {
  .react-dropdown-select-dropdown {
    display: none !important;
  }
}

.h-full {
  height: calc(100vh - 150px);
  width: 98%;
}

.w-fit {
  width: fit-content;
}

@keyframes scaleAnimation {
  0% {
    opacity: 0;
    transform: scale(1.5);
  }
  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes drawCircle {
  0% {
    stroke-dashoffset: $circle-length;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes drawCheck {
  0% {
    stroke-dashoffset: $check-length;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

#successAnimationCircle {
  stroke-dasharray: $circle-length $circle-length;
  stroke: #0096FF;
}

#successAnimationCheck {
  stroke-dasharray: $check-length $check-length;
  stroke: #0096FF;
}

#successAnimationResult {
  fill: #0096FF;
  opacity: 0;
}

#successAnimation.animated {
  animation: 1s ease-out 0s 1 both scaleAnimation;

  #successAnimationCircle {
    animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCircle,
      0.3s linear 0.9s 1 both fadeOut;
  }

  #successAnimationCheck {
    animation: 1s cubic-bezier(0.77, 0, 0.175, 1) 0s 1 both drawCheck,
      0.3s linear 0.9s 1 both fadeOut;
  }

  #successAnimationResult {
    animation: 0.3s linear 0.9s both fadeIn;
  }
}

.success-card {
  width: 100%;
  max-width: 550px;
  width: 550px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.w-100p {
  width: 100px;
}

.register-success::before {
  content: none !important;
}

.success-message {
  color: white;
}

.register-success {
  display: flex;
  justify-content: center;
  align-items: center;
  & > div {
    position: absolute !important;
    top: 50% !important;
    transform: translateY(-50%);
  }
  .success-card {
    max-width: 420px;
  }
}

.product-cover {
  transform: perspective(520px) rotateY(-40deg) !important;
}

.circular {
  height: 100px;
  width: 100px;
  position: relative;
  transform: scale(0.7);
}
.circular .inner {
  position: absolute;
  z-index: 6;
  top: 50%;
  left: 50%;
  height: 80px;
  width: 80px;
  margin: -40px 0 0 -40px;
  background: #dde6f0;
  border-radius: 100%;
}
.circular .number {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
  font-size: 18px;
  font-weight: 500;
  color: #0096FF;
}
.circular .bar {
  position: absolute;
  height: 100%;
  width: 100%;
  background: #fff;
  -webkit-border-radius: 100%;
  clip: rect(0px, 100px, 100px, 50px);
}
.circle .bar .progress {
  position: absolute;
  height: 100%;
  width: 100%;
  -webkit-border-radius: 100%;
  clip: rect(0px, 50px, 100px, 0px);
  background: #0096FF;
}
.circle .left .progress {
  z-index: 1;
  animation: left 4s linear both;
}
@keyframes left {
  100% {
    transform: rotate(180deg);
  }
}
.circle .right {
  transform: rotate(180deg);
  z-index: 3;
}
.circle .right .progress {
  animation: right 4s linear both;
  animation-delay: 4s;
}
@keyframes right {
  100% {
    transform: rotate(180deg);
  }
}

.upload-input>label{
  border-color:  #0096FF;
  max-width: 100%;
}
.upload-input path{
  fill:  #0096FF;
}
.insr{
  font-size: 13px;
  font-weight: 500;
}
.link:hover{
  color:$color-primary;
}
.link{
  cursor: pointer;
}