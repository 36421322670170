﻿$white: #ffffff;
$blue-grey-400: #78909c !default;
$grey-900: #212121 !default;
$purple-400: #c18eda !default;
$purple-500: #482883 !default;
$purple-600: #482883 !default;

.btn-primary {
  background-color: #0096FF !important;
  border: 1px solid #0096FF !important;
}
a {
  color: #0096FF;
}

.btn-link {
  color: #0096FF !important;
}

.theme-purple {
  ::selection {
    color: $white;
    background: $purple-500;
  }

  .page-loader-wrapper {
    background: $purple-500;
  }

  #left-sidebar .nav-tabs .nav-link.active {
    color: $purple-500;
  }

  &:before,
  &:after {
    background: $purple-500;
  }

  #wrapper {
    &:before,
    &:after {
      background: $purple-500;
    }
  }

  .sidebar-nav .metismenu > li.active > a {
    border-left-color: $purple-500;
  }

  .navbar-nav ul.menu-icon {
    background: $purple-500;
  }

  .fancy-checkbox {
    input[type="checkbox"] {
      &:checked + span:before {
        background: $purple-500;
        border-color: $purple-500;
      }
    }
  }

  .chat-widget {
    li {
      &.right {
        .chat-info {
          background: $purple-500;
          color: $white;

          &:before {
            border-left: 10px solid $purple-500;
          }
        }
      }
    }
  }

  .fc
    .fc-view-container
    .fc-view.fc-basic-view
    > table
    > thead
    tr
    th.fc-widget-header,
  .fc-view.fc-dayGrid-view > table > thead tr th.fc-widget-header {
    background: $purple-500;
    border-color: $purple-500;
    color: $white;
    border: 1px solid #eee;
    padding: 10px;
  }

  .blog-page .single_post .footer .stats li a:hover {
    color: $purple-500;
  }

  .auth-main {
    .btn-primary {
      background: $purple-500;
      border-color: $purple-500;
    }
  }

  .sidebar-nav .metismenu > li i {
    color: $purple-500;
  }

  .right_chat li a:hover {
    .media-object {
      border-color: $purple-500;
    }
  }
}
